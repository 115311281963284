/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import type { ComponentType } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIdle } from 'react-use';
import { getPlayerScript } from 'helpers/utils/media/getPlayerScript';
import { useCustomFullscreen } from 'helpers/hooks/useCustomFullscreenModeObserver';
import type { EpisodeMeta, MediaItem } from 'helpers/types/jwplayer-subgraph';
import type { ClassValue } from 'clsx';
import clsx from 'clsx';
import { useToggle } from 'helpers/hooks/useToggle';
import {
  usePlayerStateContext,
  usePlayerUpdaterContext
} from 'contexts/PlayerContext';
import { useRegisterPlayerAnalyticsEvents } from 'helpers/hooks/useRegisterPlayerAnalyticsEvents';
import { useAnalyticsContext } from '@pocketoutdoormedia/analytics-provider';
import { useAdsWithExpirationTime } from 'helpers/hooks/useAdsWithExpirationTime';
import { useHandleKeyDown } from 'helpers/hooks/useHandleKeyDown';
import PianoDialogMountingPoints from 'components/01-atoms/PianoDialogMountingPoints';
import PianoModalMountingPoints from 'components/02-molecules/PianoModalMountingPoints';
import { usePianoWatchTimes } from 'helpers/hooks/usePianoWatchTimes';
import { getMediaPlaylist } from 'helpers/utils/media/getMediaPlaylist';
import { useUserType } from 'helpers/hooks/useUserType';
import { getAdsConfig } from 'helpers/utils/getAdsConfig';
import { PlayerContextProvider } from 'providers/PlayerContextProvider';
import { removeQueryParams } from 'helpers/utils/url/removeQueryParams';
import { useRouter } from 'next/router';
import VideoPlayer from 'components/01-atoms/VideoPlayer';
import Image from 'next/image';
import { usePianoExperience } from 'helpers/hooks/usePianoExperience';
import type { PianoContentType } from 'helpers/types/piano';
import isDom from 'helpers/utils/isDom';
import { useTailwindBreakpoint } from 'helpers/hooks/useTailwindBreakpoint';
import { usePlayerScriptReady } from 'helpers/hooks/usePlayerScriptReady';
import { useWatchRegWall } from 'helpers/hooks/useWatchRegWall';
import { getImageByWidth } from 'helpers/utils/getImageByWidth';

import PianoPlaceholderTemplates from '../PianoPlaceholderTemplates';

import { prepareCustomFullscreen } from './helpers';
import { BackButton } from './BackButton';
import { TRACKING_CONTENT_TYPE } from './constants';

export type BaseProps = {
  className?: ClassValue;
  closeOnOneHundredPercent?: boolean;
  file?: Nullable<string>;
  isPlayerPaused?: boolean;
  isSticky?: boolean;
  onBack?: VoidFunction;
  onPause?: VoidFunction;
  onResume?: VoidFunction;
  onSeek?: (event: jwplayer.SeekParam) => void;
  onTime?: (event: jwplayer.TimeParam) => void;
  playerId: string;
  playerScript?: string;
  playlist?: Nullable<string>;
  renderVideoControls?: () => JSX.Element;
  seekTo?: number;
  shouldDisplayBackButton?: boolean;
};

type PianoProps = {
  contentType: PianoContentType;
  episodeMeta?: EpisodeMeta;
  mediaItem: MediaItem;
};

type WithPianoProps = BaseProps &
  PianoProps & {
    shouldUsePiano: true;
  };

type WithoutPianoProps = BaseProps &
  Partial<PianoProps> & {
    shouldUsePiano?: false;
  };

type Props = WithoutPianoProps | WithPianoProps;

const isPianoEnabled = process.env.NEXT_PUBLIC_PIANO_DISABLED !== 'true';

const EnhancedVideoPlayer = ({
  className,
  closeOnOneHundredPercent = false,
  contentType,
  episodeMeta,
  file,
  isPlayerPaused,
  isSticky,
  mediaItem,
  onBack,
  onPause,
  onResume,
  onSeek,
  onTime,
  playerId,
  playerScript,
  playlist,
  renderVideoControls,
  seekTo = 0,
  shouldDisplayBackButton,
  shouldUsePiano = false
}: Props) => {
  const {
    containerRef,
    disableFullscreen,
    isFullscreenMode,
    toggleFullScreen
  } = useCustomFullscreen();
  const isIdle = useIdle(2000);
  const { isLoggedIn } = useUserType();
  const [isPlayerReadyForPiano, setPlayerReadyForPiano] = useState(false);
  const [isVideoEnd, { setTrue: setIsVideoEnd }] = useToggle(false);
  const { playerRef } = usePlayerStateContext();
  const { setPlayerRef } = usePlayerUpdaterContext();
  const { isScreenNarrowerThan } = useTailwindBreakpoint();
  const isMobile = isScreenNarrowerThan('narrow');
  const router = useRouter();

  const handleOnBackWithUrlCleanup = useCallback(() => {
    onBack?.();
    removeQueryParams({
      paramsToRemove: ['mode'],
      router,
      shallow: true
    });
  }, [onBack, router]);

  const hasContentLink = Boolean(file || playlist);

  const { fullScreenOpened } = useAnalyticsContext();

  const isAdsFreeByExpirationTime = useAdsWithExpirationTime(
    playerRef,
    playerId
  );

  const { isAdsFree, mediaId } = mediaItem;

  const advertising = getAdsConfig({
    isAdsFree: isAdsFreeByExpirationTime || isAdsFree,
    mediaId,
    playerId
  });

  const trackingContentType = TRACKING_CONTENT_TYPE[contentType];

  useRegisterPlayerAnalyticsEvents({
    contenttype: trackingContentType,
    mediaItem,
    onTime,
    onVideoCompleted: setIsVideoEnd,
    player: playerRef,
    playerId
  });

  useEffect(() => {
    if (closeOnOneHundredPercent && isVideoEnd) {
      handleOnBackWithUrlCleanup();
    }
  }, [closeOnOneHundredPercent, isVideoEnd, handleOnBackWithUrlCleanup]);

  const handleEscapeDown = useCallback(() => {
    onBack?.();
    disableFullscreen();
  }, [onBack, disableFullscreen]);

  const handleOnEscapeDown = useHandleKeyDown('Escape', handleEscapeDown);

  const isBackButtonVisible =
    !!onBack &&
    !isMobile &&
    (isPlayerPaused || !isIdle || shouldDisplayBackButton);

  const shouldUsePianoBeforeAds = useMemo(
    () =>
      mediaItem?.meterFlow === 'REGISTER' ||
      mediaItem?.meterFlow === 'LOCKED' ||
      (mediaItem?.meterFlow === 'METER' && !isLoggedIn),
    [isLoggedIn, mediaItem?.meterFlow]
  );

  const playerReadyForPianoHandler = useCallback(() => {
    setPlayerReadyForPiano(true);
  }, []);

  useEffect(() => {
    // This will set ready flag to true when no video is shown to the user
    // It will support piano for blured image content
    if (!hasContentLink) {
      playerReadyForPianoHandler();
    }
  }, [hasContentLink, playerReadyForPianoHandler]);

  const handlePlayerReady = useCallback(
    (player) => {
      setPlayerRef(player);

      if (seekTo) player.seek(seekTo);
      prepareCustomFullscreen(playerId, toggleFullScreen);

      if (shouldUsePianoBeforeAds) {
        player.on('beforePlay', playerReadyForPianoHandler);
      } else {
        player.on('firstFrame', playerReadyForPianoHandler);
      }
    },
    [
      setPlayerRef,
      seekTo,
      toggleFullScreen,
      shouldUsePianoBeforeAds,
      playerReadyForPianoHandler
    ]
  );

  const isReady = usePlayerScriptReady();

  const isStickyOrFullScreen = isSticky || isFullscreenMode;

  const shouldPianoBeEnabled =
    shouldUsePiano &&
    isPlayerReadyForPiano &&
    !!mediaItem &&
    isDom() &&
    isPianoEnabled;

  usePianoExperience({
    contentType,
    enabled: shouldPianoBeEnabled,
    episodeMeta,
    meterFlow: mediaItem?.meterFlow
  });

  const shouldPianoTimeCountingBeEnabled =
    shouldPianoBeEnabled &&
    hasContentLink &&
    mediaItem?.meterFlow === 'METER' &&
    !episodeMeta?.episode;

  usePianoWatchTimes({
    enabled: shouldPianoTimeCountingBeEnabled,
    mediaItemLink: getMediaPlaylist(mediaItem?.mediaId),
    playerRef
  });

  const handleOnRemove = useCallback(() => {
    playerRef?.pauseAd(true);
  }, [playerRef]);

  useEffect(() => {
    if (isFullscreenMode) fullScreenOpened({ name: mediaItem.title });
  }, [isFullscreenMode, mediaItem.title]);

  // watch-regwall
  useWatchRegWall(mediaItem);

  return (
    <>
      <PianoPlaceholderTemplates />
      <div ref={containerRef}>
        {/* TODO: this should have .h-screen class but it makes outside header non-interactive. This might be a non-issue if piano modal styles has height of 100dvh */}
        <PianoModalMountingPoints className="z-[100] fixed top-0 right-0 w-screen" />
        <div
          className={clsx(className, {
            'fixed w-screen h-dvh max-h-full left-0 top-0 z-50':
              isStickyOrFullScreen,
            'sm:relative w-full aspect-w-16 aspect-h-9': !isStickyOrFullScreen
          })}
        >
          <div
            className="absolute top-0 left-0 grid place-items-center z-10 bg-black w-full h-full"
            id="enhanced-video-player"
            onDoubleClick={toggleFullScreen}
            onKeyDown={handleOnEscapeDown}
            role="none"
          >
            <PianoDialogMountingPoints
              className={`fixed sm:absolute sm:bottom-[auto] sm:top-0 right-0 ${
                !isStickyOrFullScreen ? 'bottom-0' : 'top-0'
              }`}
            />
            {isBackButtonVisible && (
              <BackButton onClick={handleOnBackWithUrlCleanup} />
            )}
            {!hasContentLink && (
              <Image
                alt="Hikers on the mountain trail"
                fill
                sizes="100vw"
                src="/piano-modal/background-1.jpg"
              />
            )}
            {hasContentLink && isReady && (
              <>
                {renderVideoControls?.()}
                <VideoPlayer
                  advertising={advertising}
                  classes="absolute w-full h-full atomic-video"
                  file={file}
                  image={getImageByWidth(mediaItem.images, 1200)?.src}
                  isAutoPlay
                  onPause={onPause}
                  onReady={handlePlayerReady}
                  onRemove={handleOnRemove}
                  onResume={onResume}
                  onSeek={onSeek}
                  onTime={onTime}
                  playerId={playerId}
                  playerScript={playerScript || getPlayerScript(playerId)}
                  playlist={playlist}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const withPlayerContext =
  <P extends object>(Component: ComponentType<P>) =>
  (props: Props) => (
    <PlayerContextProvider>
      <Component {...(props as P)} />
    </PlayerContextProvider>
  );

export default withPlayerContext(EnhancedVideoPlayer);
